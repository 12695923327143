import React, { useState } from 'react';
import { Alert, Button, Stack, Typography } from '@mui/material';
import { FormState } from './Form';
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from '@mui/x-date-pickers';
import { useTranslation } from 'react-i18next';

export default function Page(props) {
    const { data, handleChange, setFormState, maxEndTime, TitleIcon, minStartTime } = props;
    const [error, setError] = useState('');
    const { t } = useTranslation();

    const handleCancel = () => {
        console.log('Cancel button clicked');
        setFormState(FormState.SELECTION);
    };

    const handleNext = () => {
        console.log('Next button clicked');
        setFormState(FormState.SUBMIT);
    };

    const validateTime = (name, value) => {
        if (name === 'start' && data.end && (value.isAfter(data.end) || value.isSame(data.end))) {
            return t('start-time-validation');
        } else if (name === 'end' && data.start && (value.isBefore(data.start) || value.isSame(data.start))) {
            return t('end-time-validation');
        } else if (minStartTime && name === 'start' && value.isBefore(minStartTime)) {
            return t('min-start-time-validation');
        } else if (maxEndTime && name === 'end' && value.isAfter(maxEndTime)) {
            return t('max-end-time-validation');
        } else {
            return '';
        }
    };

    const handleTimeChange = (name, value) => {
        console.log('Time Change:', name, value);
        handleChange({
            target: {
                name: name,
                value: value,
            }
        });

        const validationError = validateTime(name, value);
        setError(validationError);
    };

    const isNextDisabled = !!error || !data.start || !data.end;

    return (
        <Stack spacing={2} sx={{ mt: 4 }}>
            <Typography variant="h3">{data.title}</Typography>
            <Typography variant="h4">
                {TitleIcon && <TitleIcon style={{ marginRight: '8px' }} />}
                {t(`formState-type.${data.type}`)}
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                    label={t('start')}
                    views={["year", "month", "day", "hours", "minutes"]}
                    format={t('date-time-format')}
                    ampm={false}
                    value={data.start}
                    minDate={minStartTime}
                    onChange={(value) => handleTimeChange('start', value)}
                    minDateTime={minStartTime}
                />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                    label={t('end')}
                    views={["year", "month", "day", "hours", "minutes"]}
                    format={t('date-time-format')}
                    ampm={false}
                    value={data.end}
                    onChange={(value) => handleTimeChange('end', value)}
                    minDateTime={data.start || minStartTime}
                    maxDateTime={maxEndTime}
                />
            </LocalizationProvider>
            {error && <Alert variant='filled' severity="warning">{error}</Alert>}
            <Stack spacing={2} direction="row">
                <Button variant="contained" fullWidth color="secondary" onClick={handleCancel}>
                    {t('cancel')}
                </Button>
                <Button variant="contained" fullWidth color="primary" onClick={handleNext} disabled={isNextDisabled}>
                    {t('next')}
                </Button>
            </Stack>
        </Stack>
    );
}